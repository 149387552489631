/*
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2024-03-13 09:11:50
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-03-30 17:03:29
 */
import { createApp } from "vue";
import App from "./App.vue";
import "nprogress/nprogress.css";
// import "@/assets/app.css";
import "vant/lib/index.css";
import router from "./router/router";
import config from "./config/install_config";
import http from "./http/http";

import {
  showToast,
  showLoadingToast,
  showSuccessToast,
  showFailToast,
  closeToast,
  Progress,
  Icon,
  showNotify,
  CountDown,
  Loading,
  showDialog,
  Field  ,ImagePreview
} from "vant";

const app = createApp(App);
app.use(router);
app.use(config);
app.use(http);
app.use(Progress);
app.use(Icon);
app.use(CountDown);
app.use(Loading);
app.use(Field);
app.use(ImagePreview);

//全局使用showToast组件
app.config.globalProperties.$toast = {
  show: showToast,
  loading: showLoadingToast,
  success: showSuccessToast,
  fail: showFailToast,
  close: closeToast,
  showNotify: showNotify,
  showDialog :showDialog ,
};

app.mount("#app");
