/*
 * @Description:
 * @Author: 琢磨先生
 * @Date: 2024-03-16 00:06:30
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-03-31 17:35:33
 */
/*
 * @Description:
 * @Author: 琢磨先生
 * @Date: 2024-03-16 00:06:30
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-03-28 15:24:48
 */
import { createRouter, createWebHistory } from "vue-router";
import NProgress from "nprogress";
import config from "@/config/config";
import Layout from "@/layout";

var base_title = "友邦北京2023年度荣誉盛典";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/home"),
    meta: {
      title: `${base_title}\r\n线上特别策划"`,
      desc: "助力年度荣誉 感受璀璨光芒",
    },
  },
  {
    path: "/test/home",
    name: "home_test",
    component: () => import("@/views/home/index_test"),
    meta: {
      title: `${base_title}\r\n线上特别策划"`,
      desc: "助力年度荣誉 感受璀璨光芒",
    },
  },
  {
    path: "/win",
    name: "win",
    component: () => import("@/views/home/win"),
    meta: {
      title: `${base_title}\r\n线上特别策划"`,
      desc: "助力年度荣誉 感受璀璨光芒",
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login"),
    meta: {
      title: `${base_title}\r\n线上特别策划"`,
      desc: "助力年度荣誉 感受璀璨光芒",
    },
  },
  {
    path: "/voice/enter",
    name: "voice_enter",
    component: () => import("@/views/voice/enter"),
    meta: {
      title: `${base_title}\r\n好声音征集`,
      desc: "助力年度荣誉 感受璀璨光芒",
    },
  },
  {
    path: "/voice/info",
    name: "voice_info",
    component: () => import("@/views/voice/info"),
    meta: {
      title: `${base_title}\r\n好声音征集`,
      desc: "助力年度荣誉 感受璀璨光芒",
    },
  },
  {
    path: "/slogan",
    name: "slogan",
    component: () => import("@/views/slogan/index"),
    meta: {
      title: `${base_title}\r\n口号征集`,
      desc: "助力年度荣誉 感受璀璨光芒",
    },
  },

  {
    path: "/slogan/enter",
    name: "slogan_enter",
    component: () => import("@/views/slogan/enter"),
    meta: {
      title: `${base_title}\r\n口号征集`,
      desc: "助力年度荣誉 感受璀璨光芒",
    },
  },
  {
    path: "/slogan/ranked",
    name: "slogan_ranked",
    component: () => import("@/views/slogan/ranked"),
    meta: {
      title: `${base_title}\r\n口号征集`,
      desc: "助力年度荣誉 感受璀璨光芒",
    },
  },

  {
    path: "/slogan/dtl",
    name: "slogan_dtl",
    component: () => import("@/views/slogan/dtl"),
    meta: {
      title: `${base_title} 口号征集`,
      desc: "助力年度荣誉 感受璀璨光芒",
    },
  },
  {
    path: "/slogan/rebuild",
    name: "slogan_rebuild",
    component: () => import("@/views/slogan/rebuild"),
    meta: {
      title: `${base_title} 口号征集`,
      desc: "助力年度荣誉 感受璀璨光芒",
    },
  },

  //老照片
  {
    path: "/photo",
    name: "photo",
    component: () => import("@/views/photo"),
    meta: {
      title: `${base_title} 老照片征集`,
      desc: "助力年度荣誉 感受璀璨光芒",
    },
  },
  {
    path: "/photo/topic1",
    name: "photo_topic1",
    component: () => import("@/views/photo/topic1"),
    meta: {
      title: `${base_title} 老照片征集`,
      desc: "助力年度荣誉 感受璀璨光芒",
    },
  },
  {
    path: "/photo/topic2",
    name: "photo_topic2",
    component: () => import("@/views/photo/topic2"),
    meta: {
      title: `${base_title} 老照片征集`,
      desc: "助力年度荣誉 感受璀璨光芒",
    },
  },
  {
    path: "/photo/enter",
    name: "photo_enter",
    component: () => import("@/views/photo/enter"),
    meta: {
      title: `${base_title} 老照片征集`,
      desc: "助力年度荣誉 感受璀璨光芒",
    },
  },
  {
    path: "/photo/dtl",
    name: "photo_dtl",
    component: () => import("@/views/photo/dtl"),
    meta: {
      title: `${base_title} 口号征集`,
      desc: "助力年度荣誉 感受璀璨光芒",
    },
  },
  {
    path: "/photo/ranked",
    name: "photo_ranked",
    component: () => import("@/views/photo/ranked"),
    meta: {
      title: `${base_title} 老照片征集`,
      desc: "助力年度荣誉 感受璀璨光芒",
    },
  },

  //用户投票
  {
    path: "/vote/slogan",
    name: "vote_slogan",
    component: () => import("@/views/vote/slogan"),
    meta: {
      title: `${base_title} 口号投票`,
      desc: "助力年度荣誉 感受璀璨光芒",
      customShare: true, //自定义分享
    },
  },
  {
    path: "/vote/photo",
    name: "vote_photo",
    component: () => import("@/views/vote/photo"),
    meta: {
      title: `${base_title} 老照片投票`,
      desc: "助力年度荣誉 感受璀璨光芒",
      customShare: true, //自定义分享
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});

//需要检测登录的页面
var arr = ["voice_enter", "slogan_enter","slogan_dtl","slogan_ranked","photo_enter","photo_ranked","photo_dtl"];

/**
 * 前置守卫
 */
router.beforeEach(async (to, from, next) => {
  NProgress.start();
  if (arr.includes(to.name)) {
    var token = localStorage.getItem(config.TOKEN);
    if (!token) {
      //记录需要回跳的地址
      localStorage.setItem(config.RETURN_URL, to.fullPath);
      console.log(to.fullPath);
      next("/login");
      return;
    }
  }
  next();
});
/**
 * 后置守卫
 */
router.afterEach((to, from, next) => {
  document.title = to.meta?.title ?? "友邦";
  NProgress.done();
});

export default router;
